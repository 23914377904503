import { render, staticRenderFns } from "./Jaarverslagen.vue?vue&type=template&id=3e4f0a76&scoped=true&"
import script from "./Jaarverslagen.vue?vue&type=script&lang=ts&"
export * from "./Jaarverslagen.vue?vue&type=script&lang=ts&"
import style0 from "./Jaarverslagen.vue?vue&type=style&index=0&id=3e4f0a76&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e4f0a76",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})
